<template>
  <div class="b-page">
    <div class="b-page__content">
      <div class="container block-margin-bottom">
        <div
            style="display: block; position: relative; margin-top: 50px"
            id="singup">
          <div v-show="!saved">
            <div style="text-align: center"><h6>Внимание! Техосмотр транспорта категории М2,М3 производится понедельник,
              пятница 9:00-18:00. Запись производится за 5 рабочих дней.</h6></div>
            <div style="text-align: center"><h1>Запись на техосмотр</h1></div>
            <div style="width: 600px; margin-left: auto; margin-right: auto">
              <button
                  class=""
                  style="
                  background-color: #29860c;
                  color: #fff;
                  height: 50px;
                  width: 290px;
                  border: 1px solid #29860c;
                  margin: 0 5px;
                "
                  @click="selectedTypeFace=2"
              >
                Юр. лицо
              </button>
              <button
                  class=""
                  style="
                  background-color: #29860c;
                  color: #fff;
                  height: 50px;
                  width: 290px;
                  border: 1px solid #29860c;
                  margin: 0 5px;
                "
                  @click="selectedTypeFace=1"
              >
                Физ. лицо
              </button>
            </div>

            <div style="margin-top: 10px;margin-left: 180px" v-show="selectedTypeFace!=null">
              <template v-for="(item, index) in singupServices" :key="index">
                <button v-show="item.view" class="singup-type-button" @click="selectTypeTS(item.type)">{{ item.name }}
                </button>
              </template>
            </div>
            <div
                id="singup-date"
                style="margin-top: 10px"
                v-show="selectedTypeTS!=null"
            >
              <table style="width: 100%">
                <tbody>
                <tr v-for="(week,index) in dates.value" v-bind:key="index">
                  <template v-for="day in week" v-bind:key="day.date"
                  >
                    <td style="padding: 5px">
                      <button
                          v-text="day.date"
                          class="singup-button"
                          :disabled="day.isNotActive"
                          @click="selectDate(day.date)"
                      ></button>
                    </td
                    >
                  </template>
                </tr>
                </tbody>
              </table>
              <div style="margin: 10px; height: 50px">
                <div
                    @click="previousMonth"
                    style="float: left; cursor: pointer; color: black"
                    v-show="currentM>0"
                >
                  <h6>Предыдущий месяц</h6>
                </div>
                <div
                    @click="nextMonth"
                    style="float: right; cursor: pointer; color: black"
                >
                  <h6>Следующий месяц</h6>
                </div>
              </div>
            </div>
            <div id="singup-time" v-show="selectedDate!=''">
              <table style="width: 100%">
                <tbody>

                <tr v-for="(row,index) in times.value" v-bind:key="index">
                  <template v-for="time in row" v-bind:key="time.time"
                  >
                    <td style="padding: 5px">
                      <button
                          v-text="time.time"
                          class="singup-button"
                          :disabled="time.isNotActive"
                          @click="selectTime(time.time)"
                      ></button>
                    </td
                    >
                  </template>
                </tr>
                </tbody>
              </table>


            </div>

            <div
                class="form"
                style="
                width: 400px;
                margin-left: auto;
                margin-right: auto;
                padding-top: 20px;
              "
            >
              <div class="">
                <h5>
                  Запись: {{ getTypeFace }} {{ selectedTypeTS }} {{ selectedDate }}
                  {{ selectedTime }}
                </h5>
              </div>
              <div v-show="selectedTime!=''">
                <div class=""><h3>Введите ваши данные:</h3></div>
                <div v-show="selectedTypeFace>1">
                  <input
                      name="firm"
                      v-model="firm"
                      type="text"
                      placeholder="Название организации"
                      class="form_input"
                  />
                  <input
                      v-model="fio"
                      name="fio2"
                      type="text"
                      placeholder="ФИО представителя"
                      class="form_input"
                  />
                  <input
                      v-model="inn"
                      name="inn"
                      type="text"
                      placeholder="ИНН"
                      class="form_input"
                  />
                </div>
                <input
                    v-model="fio"
                    v-show="selectedTypeFace==1"
                    name="fio"
                    type="text"
                    placeholder="ФИО"
                    class="form_input"
                />
                <input
                    v-model="phone"
                    name="tel"
                    type="tel"
                    placeholder="Телефон"
                    class="form_input"
                />
                <input
                    v-model="email"
                    name="email"
                    type="text"
                    placeholder="E-mail"
                    class="form_input"
                />
                <input
                    v-model="model"
                    name="marka"
                    type="text"
                    placeholder="регистрационный знак"
                    class="form_input"
                />
                <button
                    class=""
                    style="
                    background-color: #29860c;
                    color: #fff;
                    height: 50px;
                    width: 100%;
                    margin-left: 25px;
                    border: 1px solid #29860c;
                  "
                    @click="saveSingup"
                >
                  Отправить
                </button>
              </div>
            </div>
          </div>
          <div v-show="saved">
            <h5>
              Вы записаны на технический осмотр {{ selectedDate }}
              {{ selectedTime }}
            </h5>
          </div>
          <br/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {ref, reactive, onMounted, computed} from 'vue'
import myStore from '@/store/Store'

export default {
  name: "Singup",
  setup() {
    let dayPlus = 0; //используется для добавления дней в запись ( для автобусов не занее чем за 5 дней)
    const singupServices = myStore().singupServices
    let startTime = {h: 0, m: 0, s: 0}
    let endTime = {h: 0, m: 0, s: 0}
    let workDay = {1: true, 2: true, 3: true, 4: true, 5: true, 6: true, 7: true}
    const stepMinutes = 60
    const ptoName = myStore().ptoName
    const apiUrl = myStore().apiUrl
    const test = ref("asdfg-----------")
    const dates = reactive([])
    const times = reactive([])
    let currentDate = null
    const currentM = ref(0)
    const selectedDate = ref("")
    const selectedTime = ref("")
    const selectedTypeFace = ref(null)
    const selectedTypeTS = ref(null)
    const fio = ref("")
    const inn = ref("")
    const firm = ref("")
    const model = ref("")
    const phone = ref("")
    const email = ref("")
    const saved = ref(false)
    const blackTime= ref({})
    const saveSingup = async () => {
      if (selectedTypeFace.value != null) {
        const formData = new FormData();

        if (selectedTypeFace.value == 2) {
          formData.append("fio", fio.value);
          formData.append("firm", firm.value);
          formData.append("inn", inn.value);
          formData.append("phone", phone.value);
          formData.append("email", email.value);
          formData.append("model", model.value);
          formData.append("typeface", selectedTypeFace.value);
          formData.append("pto", ptoName);
          formData.append(
              "datetime",
              selectedDate.value + " " + selectedTime.value
          );
          formData.append("typets", selectedTypeTS.value);
        }
        if (selectedTypeFace.value == 1) {
          formData.append("fio", fio.value);
          formData.append("firm", "");
          formData.append("inn", "");
          formData.append("phone", phone.value);
          formData.append("email", email.value);
          formData.append("model", model.value);
          formData.append("typeface", selectedTypeFace.value);
          formData.append("pto", ptoName);
          formData.append(
              "datetime",
              selectedDate.value + " " + selectedTime.value
          );
          formData.append("typets", selectedTypeTS.value);
        }
        try {
          await fetch(apiUrl + "/singups/add", {
            method: "POST",
            mode: "cors",
            body: formData,
          });
          saved.value = true;
        } catch (error) {
          console.log(error);
        }
      }
    }
    const createTimes = () => {
      const d = new Date()
      const d2 = new Date()
      d2.setSeconds(endTime.s)
      d2.setHours(endTime.h)
      d2.setMinutes(endTime.m)
      d.setHours(startTime.h)
      d.setMinutes(startTime.m)
      d.setSeconds(startTime.s)
      //console.log(d)
      let b = true;
      let i = 0;
      let rowTimes = []
      times.value = []
      while (b) {
        const time = {
          time: ("0" + d.getHours()).slice(-2) +
              ":" +
              ("0" + d.getMinutes()).slice(-2),
          isNotActive: false,

        }
        // console.log(time.time)
        //  console.log(blackTime.value[time.time])
        if(blackTime.value[time.time]){
          time.isNotActive=true
        }
        i++;
        if (i > 4) {
          times.value.push(rowTimes)
          rowTimes = []
          i = 1;
        }
        rowTimes.push(time)


        d.setTime(d.getTime() + stepMinutes * 60000)
        if (d.getTime() > d2.getTime()) {
          b = false;
        }

      }
      if (i > 0) {
        times.value.push(rowTimes)
      }
    }
    const createDates = () => {
      const d = new Date();
      dates.value = [];
      d.setDate(1);
      if (currentM.value > 0) {
        d.setMonth(d.getMonth() + currentM.value);
      }
      let day = d.getDay();
      if (day == 0) {
        day = 7;
      }
      day = day - 2;
      d.setDate(-day);
      test.value =
          ("0" + d.getDate()).slice(-2) +
          "." +
          ("0" + (d.getMonth() + 1)).slice(-2) +
          "." +
          d.getFullYear();
      for (let i = 0; i < 6; i++) {
        const week = [];
        for (let i2 = 1; i2 < 8; i2++) {
          const date = {
            date:
                ("0" + d.getDate()).slice(-2) +
                "." +
                ("0" + (d.getMonth() + 1)).slice(-2) +
                "." +
                d.getFullYear(),
            isNotActive: true,
          };
          if (d.getTime() > currentDate.getTime() && workDay[i2]) {
            date.isNotActive = false;
          }


          //   console.log(d.getTime() + "-------" + currentDate.getTime());
          week.push(date);
          d.setDate(d.getDate() + 1);
        }

        dates.value.push(week);
      }
      // console.log(dates);
    }

    const calcDateArray = () => {
      currentDate = new Date();
      currentDate.setDate(currentDate.getDate() + dayPlus)
      createDates();
    }
    const selectDate = async (date) => {
      const response = await fetch(apiUrl + '/singups/flist/' + date + '/' + myStore().ptoName)
      if (response.ok) { // если HTTP-статус в диапазоне 200-299
        // получаем тело ответа (см. про этот метод ниже)
        blackTime.value={}
        const dt = await response.json();
        if(dt.length>0){
          for (let i=0;i<dt.length;i++){
            const tarr=dt[i].datetime.split(" ")
            blackTime.value[tarr[1]]=true
          }
        }
        // console.log(dt)
        selectedDate.value = date;
        createTimes()
      }
    }
    const selectTime = (time) => {
      selectedTime.value = time;
    }
    const selectTypeTS = (type) => {
      selectedTypeTS.value = type;
      dayPlus = 0;
      startTime = {h: 8, m: 0, s: 0}
      workDay = {1: true, 2: true, 3: true, 4: true, 5: true, 6: true, 7: false}
      endTime = {h: 17, m: 0, s: 0}
      switch (type) {
        case "М2":
          dayPlus = 7;
          startTime = {h: 9, m: 0, s: 0}
          endTime = {h: 17, m: 0, s: 0}
          workDay = {1: true, 2: false, 3: false, 4: false, 5: true, 6: false, 7: false}
          break
        case "М3":
          dayPlus = 7;
          startTime = {h: 9, m: 0, s: 0}
          endTime = {h: 17, m: 0, s: 0}
          workDay = {1: true, 2: false, 3: false, 4: false, 5: true, 6: false, 7: false}
          break
      }
      selectedDate.value = ""
      selectedTime.value = ""
      calcDateArray()
      //  console.log(type)
      // console.log(dayPlus)
    }
    const selectTypeFace = (type) => {
      selectedTypeFace.value = type;
    }

    const nextMonth = () => {
      currentM.value++;
      createDates();
    }
    const previousMonth = () => {
      if (currentM.value > 0) {
        currentM.value--;
        createDates();
      }
    }
    onMounted(() => {
      calcDateArray()
    })
    const getTypeFace = computed(() => {
      if (selectedTypeFace.value == 1) {
        return "Физ. лицо";
      }
      if (selectedTypeFace.value != null) {
        return "Юр. лицо";
      }
      return "";
    })
    return {
      test,
      dates,
      currentDate,
      currentM,
      selectedDate,
      selectedTime,
      selectedTypeFace,
      selectedTypeTS,
      fio,
      inn,
      firm,
      model,
      phone,
      email,
      saved,
      selectDate,
      selectTime,
      selectTypeTS,
      selectTypeFace,
      calcDateArray,
      nextMonth,
      previousMonth,
      saveSingup,
      getTypeFace,
      singupServices,
      times,
      blackTime
    }
  }
}
</script>

<style scoped>
.singup-button {
  width: 100%;
  border: 1px solid #29860c;
  background-color: #fff;
  height: 50px;
  color: black;
  font-size: 14px;
}

.singup-button:hover {
  background-color: #29860c;
  color: #fff;
}

.singup-button:disabled {
  background-color: #cae5c2;
  color: #fff;
}

.form_input {
  border: 1px solid #c4c4c4;
  padding: 12px 20px;
  height: 40px;
  width: 100%;
  margin-bottom: 20px;
  background-image: none;
  /* border: none;
   Пароль */

  font-size: 17px;
  line-height: 20px;
  /* identical to box height */

  /* color: #a5a5a5;*/
}

.form_input:focus {
  border: 1px solid #222;
  background-image: none;
  outline: none;
}

.singup-type-button {
  /* width: 100%;*/
  border: 1px solid #29860c;
  background-color: #fff;
  height: 50px;
  color: black;
  font-size: 14px;
  margin: 5px;
  display: table-cell;
}

.singup-type-button:hover {
  background-color: #29860c;
  color: #fff;
}

/*.row {
  display: flex;
  flex-direction: row;
}*/
</style>